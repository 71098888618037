<template>
  <div>
    <TitleMore
      :title="[`视频资料`]"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard homeVideo">
      <div
        class="homeVideoCard"
        v-for="(item,index) in hdyxList"
        :key="index"
        @click="playVideo(item.id)"
      >
	  <div class="biaoqian">
	   <img :src="require('@/assets/images/组 15.png')" alt="">
	  </div>
	  <div class="bofang">
	  	<img :src="require('@/assets/images/播放.png')" alt="">
	  </div>
        <img
          style="height:200px"
          :src="item.smallImage"
          alt=""
        >
        <div style="display:flex;justify-content: space-between;margin:10px 0px 20px 0px">
          <span class="ptitle">录入时间：{{item.publishDate}}</span>
          <span class="ptitle">浏览次数：{{item.views}}次 </span>
        </div>
        <p style="
font-size: 20px;
font-weight: bold;
color: #DB4041;
text-align:center">{{item.title}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import apiUrls from "@api";
export default {
  data() {
    return {
      title: [],
      hdyxList: [],
      total: 0,
      page: 0,
      size: 10,
      total: 0,
    };
  },

  components: {
    TitleMore,
  },

  mounted() {
    this.title.push(this.$route.query.name);
    this.getselfDyList(this.$route.query.type);
   
  },

  methods: {
    playVideo(id) {
       this.$router.push({path:'/videoShow',query:{id}})
    },
    handleSizeChange(val) {
   
    },
    handleCurrentChange(val) {
  
    },
    getselfDyList(type, page = 0, size = 10) {
      apiUrls.selfDynamicList({ node: type, size, page }).then((res) => {
        this.total = res.results.pager.totalElements;
        this.hdyxList = res.results.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.homeVideo {
	&:after{
	  content:'';
	  /*这个宽度和子元素宽度一致*/
	  width: 30%;
	}
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .homeVideoCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
	padding: 10px;
	position: relative;
	.bofang {
			  position: absolute;
			  left: 50%;
			  top: 110px;
			  transform: translate(-50%, -50%);
			  z-index: 1000;
	}
	.biaoqian {
		position: absolute;
		top: 20px;
		left: 0;
	}
	&:hover {
			  transform: translateY(-5px);
			  box-shadow: 5px 5px 5px #ccc;
	}
    .ptitle {
      font-size: 12px;
      font-weight: 500;
      color: #505052;
    }
  }
}
</style>